export enum PreferenceKeys {
  GRAPHQL_ENDPOINT = 'GRAPHQL_ENDPOINT',
  GRAPHQL_WS_ENDPOINT = 'GRAPHQL_WS_ENDPOINT',
  CROSSDOMAIN_STORAGE = 'CROSSDOMAIN_STORAGE',
  DOMAIN = 'DOMAIN',
  SERVICE_ID = 'SERVICE_ID',
  ONBOARDING_MEDIA_1 = 'ONBOARDING_MEDIA_1',
  ONBOARDING_MEDIA_2 = 'ONBOARDING_MEDIA_2',
  ONBOARDING_MEDIA_3 = 'ONBOARDING_MEDIA_3',
  ONBOARDING_MEDIA_4 = 'ONBOARDING_MEDIA_4',
  PAYMENT_LIB_LINK = 'PAYMENT_LIB_LINK',
  ACCOUNT_URL = 'ACCOUNT_URL',
  RECAPTCHA_KEY = 'RECAPTCHA_KEY',
  GOOGLEPLAY_LINK = 'GOOGLEPLAY_LINK',
  APPSTORE_LINK = 'APPSTORE_LINK',
  POLICY_LINK = 'POLICY_LINK',
  OFERTA_LINK = 'OFERTA_LINK',
  FOOTER_INFO = 'FOOTER_INFO',
  LOGO_SRC = 'LOGO_SRC',
  LOGO_WIDTH = 'LOGO_WIDTH',
  LOGO_HEIGHT = 'LOGO_HEIGHT',
  ADDITIONAL_LOGO_SRC = 'ADDITIONAL_LOGO_SRC',
  ADDITIONAL_LOGO_TEXT= 'ADDITIONAL_LOGO_TEXT',
  TITLE = 'TITLE',
  FAVICON = 'FAVICON',
  META_TITLE = 'META_TITLE',
  META_DESCRIPTION = 'META_DESCRIPTION',
  META_DESCRIPTION_IMAGE = 'META_DESCRIPTION_IMAGE',
  CLUB = 'CLUB',
  CLUB_ACCENT = 'CLUB_ACCENT',
  CLUB_PRIMARY = 'CLUB_PRIMARY',
  CLUB_PRIMARY_LIGHT = 'CLUB_PRIMARY_LIGHT',
  CLUB_BG_1 = 'CLUB_BG_1',
  CLUB_BG_2 = 'CLUB_BG_2',
  CLUB_BG_3 = 'CLUB_BG_3',
  CLUB_NAVIGATION_1 = 'CLUB_NAVIGATION_1',
  CLUB_NAVIGATION_2 = 'CLUB_NAVIGATION_2',
  CLUB_CUSTOM_LEAGUE_BANNER_URL = 'CLUB_CUSTOM_LEAGUE_BANNER_URL',
  CLUB_CUSTOM_LEAGUE_BANNER_TEXT = 'CLUB_CUSTOM_LEAGUE_BANNER_TEXT',
  PLACE_PHOTO_FILE_EXTENSION = 'PLACE_PHOTO_FILE_EXTENSION',
  PLACE_PHOTO_URL_PREFIX = 'PLACE_PHOTO_URL_PREFIX',
  PLACE_PHOTO_ACTIVE = 'PLACE_PHOTO_ACTIVE',
  PLACE_PHOTO_CHECK_BEFORE = 'PLACE_PHOTO_CHECK_BEFORE',
  WARN_BANNER_CONFIG_FILE_URL = 'WARN_BANNER_CONFIG_FILE_URL',
  DEFAULT_LOCALE = 'DEFAULT_LOCALE',
  DEFAULT_TIMEZONE = 'DEFAULT_TIMEZONE',
  PAYMENT_ADAPTER = 'PAYMENT_ADAPTER',
  MAP_MAX_ZOOM = 'MAP_MAX_ZOOM',
  MAP_PLACE_RADIUS = 'MAP_PLACE_RADIUS',
  MAP_PLACE_WEIGHT = 'MAP_PLACE_WEIGHT',
}

export type Preferences = Record<PreferenceKeys, string>;

export interface PreferencesService {
  get(key: PreferenceKeys): string;
  getNullable(key: PreferenceKeys): string | undefined;
}
